import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const Pricing = () => {
  return (
    <div className="flex flex-col items-left gap-6 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto w-3/4 py-32 md:py-0 mx-auto md:mx-0 mb-36">
      <h1 className="text-white md:text-4xl text-3xl mb-4 z-20">Pricing</h1>
      <div className="flex md:flex-row flex-col gap-6 justify-center ">
        <div className="flex-1 bg-white bg-opacity-90 backdrop-blur-lg md:py-12 py-8 rounded-xl z-20 flex flex-col justify-start items-start md:px-12 text-left gap-6 shadow-lg">
          <h1 className="text-black md:text-2xl text-2xl font-semibold">
            Free Tier
          </h1>
          <h1 className="text-black md:text-4xl text-2xl font-semibold mb-6">
            $0
          </h1>

          <ul className="flex flex-col gap-4 text-left">
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />1 Idea
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Complete Dashboard
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              AI Assistant Support
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Customer Segmentation
            </li>
          </ul>
        </div>
        <div className="flex-1 bg-bl bg-opacity-90 backdrop-blur-lg md:py-12 py-8 rounded-xl z-20 flex flex-col justify-start items-start md:px-12 text-left gap-6 shadow-lg">
          <h1 className="text-white md:text-2xl text-2xl font-semibold">
            Founder Tier
          </h1>
          <h1 className="text-white md:text-4xl text-2xl font-semibold mb-6">
            $10<span className="text-sm"> /mo</span>
          </h1>

          <ul className="flex flex-col gap-4 text-left">
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />1 Idea
            </li>
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Complete Dashboard
            </li>
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              AI Assistant Support
            </li>
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Customer Segmentation
            </li>
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Competitor Analysis
            </li>
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Social Media Posts
            </li>
            <li className="text-white text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />2 Feature Dashboards
            </li>
          </ul>
        </div>
        <div className="flex-1 bg-white bg-opacity-90 backdrop-blur-lg md:py-12 py-8 rounded-xl z-20 flex flex-col justify-start items-start md:px-12 text-left gap-6 shadow-lg">
          <h1 className="text-black md:text-2xl text-2xl font-semibold">
            Growth Tier
          </h1>
          <h1 className="text-black md:text-4xl text-2xl font-semibold mb-6">
            $50<span className="text-sm"> /mo</span>
          </h1>

          <ul className="flex flex-col gap-4 text-left mb-6">
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Unlimited Ideas
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Complete Dashboard
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              AI Assistant Support
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Customer Segmentation
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Competitor Analysis
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Social Media Posts
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Unlimited Feature Dashboards
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              Market Reports Tracking
            </li>
            <li className="text-black text-md flex flex-row gap-2 items-center">
              <FaCheckCircle size={16} />
              User Research with Real People
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
