import React from "react";
import { ContainerScroll } from "./ui/container-scroll-animation";
import { StickyScroll } from "./ui/sticky-scroll-reveal";

const Demo = () => {
  const content = [
    {
      title: "Competitor Analysis",
      description:
        "Stay ahead with our comprehensive competitor analysis tool. Track market performance, social media activity, company size, and funding insights to refine your approach and seize new opportunities.",
      content: (
        <img
          src="/c4.png"
          width={300}
          height={300}
          className=" w-full h-full object-cover object-left-top"
        />
      ),
    },
    {
      title: "Customer Segmentation",
      description:
        "Understand your target market with detailed user profiles to tailor your products for maximum impact. Generate research hypotheses, targeted questionnaires, and connect with real users for in-depth feedback and validation.",
      content: (
        <img
          src="/c1.png"
          width={300}
          height={300}
          className=" w-full h-full object-cover object-left-top"
        />
      ),
    },
    {
      title: "Chat with AI Personas",
      description:
        "Engage with realistic AI-generated personas that reflect your target customers' behaviors and needs. Test new ideas, validate concepts, and gain instant feedback to refine your products and strategies.",
      content: (
        <img
          src="/c2.png"
          width={300}
          height={300}
          className=" w-full h-full object-cover object-left-top "
        />
      ),
    },
    {
      title: "AI Assistant Support",
      description:
        "Get instant support and personalized guidance with our AI-powered chat. Gain deeper insights, track progress, streamline workflows, revisit concepts, and make informed, data-driven decisions effortlessly.",
      content: (
        <img
          src="/c3.png"
          width={300}
          height={300}
          className=" w-full h-full object-cover object-left-top"
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col items-left gap-6 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto px-6 py-32 md:py-0 mx-auto md:mx-0">
      <ContainerScroll
        titleComponent={
          <>
            <h1 className="text-4xl md:text-[6rem] text-white font-bold mt-1 leading-none">
              Transform Ideas with Smart Research
            </h1>
          </>
        }
      >
        <img
          src={"/dashboard.png"}
          className="rounded-xl w-full h-full object-cover"
          alt="Display"
        />
      </ContainerScroll>
      <div className="md:-mt-32 md:mb-48">
        <StickyScroll content={content} />
      </div>
    </div>
  );
};

export default Demo;
