import React from "react";
import { useEffect, useState } from "react";
import { BackgroundGradientAnimation } from "./ui/background-gradient-animation";
import { Button } from "./ui/moving-border";

const Hero = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="flex flex-col md:-gap-8 gap-12 absolute left-0 md:top-6 top-48 items-center w-screen rounded-xl">
        <BackgroundGradientAnimation
          gradientBackgroundStart="#000000"
          gradientBackgroundEnd="#000000"
          firstColor="0,0,0"
          secondColor="252, 143, 65"
          thirdColor="255, 204, 168"
          fourthColor="252, 143, 65"
          fifthColor="86, 115, 205"
          pointerColor="140, 100, 255"
          size="80%"
          blendingValue="hard-light"
          interactive={true}
          containerClassName="w-[97%] rounded-xl"
        ></BackgroundGradientAnimation>
        <div className="md:w-full w-screen md:-mb-6">
          <img
            src={"../lc1.png"}
            className="mr-auto z-10"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>
        <div className="md:w-full max-w-screen">
          <img
            src={"../rc1.png"}
            className="md:ml-auto z-10 ml-auto"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>
        <div className="md:w-full max-w-screen">
          <img
            src={"../lc2.png"}
            className="mr-auto z-10"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>
        <div className="md:w-full max-w-screen">
          <img
            src={"../rc22.png"}
            className="ml-auto z-10"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 justify-center items-center md:min-h-screen md:px-32 font-urbanist md:w-auto w-5/6 relative md:top-10 md:py-0 mx-auto md:mx-0 max-h-screen md:max-h-none transform translate-y-20 md:translate-y-0 md:mb-0 mb-24 ">
        <h1
          className="md:text-[6rem] text-3xl md:w-full w-full text-white md:-mb-4 font-intvar font-[600] hero-title leading-[5.25rem]"
        >
          AI-Powered <span className="font-[900]">Market Research</span>, Instantly
          for Your Startup
        </h1>
        <div className="md:px-0 md:mx-2 px-4 md:pt-8 md:pb-2 rounded-xl z-20 flex flex-col justify-center items-center text-center md:gap-12 gap-6 py-6 relative">
          <p className="text-white md:text-[1.5rem] text-lg md:w-full w-3/4 md:mb-6">
            Citrana provides market insights to help build ideas that sell.
          </p>
        </div>
        <div className="flex gap-4">
          <Button
            borderRadius="0.75rem"
            className="bg-glassbg bg-opacity-95"
            onClick={() =>
              document
                .getElementById("footer")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Join The Waitlist Now!
          </Button>
          <button
            className="bg-white text-black font-bold py-3 px-6 rounded-xl hover:bg-gray-200 transition-all duration-300"
            onClick={() =>
              window.location.href = "https://citrana-beta.netlify.app"
            }
          >
            Try the Beta Version
          </button>
        </div>
      </div>
    </>
  );
};

export default Hero;
