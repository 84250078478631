import React, { useState } from "react";
import { AnimatedTooltip } from "./ui/animated-tooltip";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      alert("Invalid email format. Please enter a valid email address.");
      return;
    }

    setLoading(true); // Set loading state to true

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxwtrrcGU6u_zilLtObAcumK9s_4gkhfBqTi88LiM87f1N2DBlXvA7IzSyJnUQVr82kTA/exec",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            email: email,
          }),
        }
      );

      console.log(response);
      const result = await response.text();
      if (response.status === "success") {
        setMessage("Successfully added to the waitlist!");
        setEmail("");
        alert("Successfully added to the waitlist!");
      } else {
        console.log(response.ok);
        setMessage("Successfully added to the waitlist!");
        setEmail("");
        alert("Successfully added to the waitlist!");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error. Please try again.");
      alert("There was an error. Please try again.");
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };

  const people = [
    {
      id: 1,
      name: "John Doe",
      designation: "Software Engineer",
      image:
        "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3387&q=80",
    },
    {
      id: 2,
      name: "Robert Johnson",
      designation: "Product Manager",
      image:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    },
    {
      id: 3,
      name: "Jane Smith",
      designation: "Data Scientist",
      image:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    },
    {
      id: 4,
      name: "Emily Davis",
      designation: "UX Designer",
      image:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGF2YXRhcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
    },
    {
      id: 5,
      name: "Tyler Durden",
      designation: "Soap Developer",
      image:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3540&q=80",
    },
    {
      id: 6,
      name: "Dora",
      designation: "The Explorer",
      image:
        "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3534&q=80",
    },
  ];

  return (
    <div className=" flex flex-col gap-4 backdrop-blur-md bg-footerbg bg-cover h-[80vh] bg-opacity-20 justify-center md:w-auto w-screen">
      <h1 className="text-bl text-xl md:text-2xl">Join the Waitlist Now!</h1>
      <div className="flex flex-row gap-4 justify-center mb-4">
        <input
          type="text"
          className="rounded-xl py-2 px-4 shadow-lg"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* <button
          className="bg-btnbg bg-center text-white rounded-xl p-2 shadow-sm hover:shadow-lg hover:text-black transition z-20"
          disabled={loading} // Disable the button when loading is true
          onClick={() => {
            handleSubmit(email);
          }}
        >
          {loading ? "Loading..." : "Join"}{" "}
        </button> */}
        <button
          className="p-[2px] relative"
          disabled={loading} // Disable the button when loading is true
          onClick={() => {
            handleSubmit(email);
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-bl to-orng rounded-lg" />
          <div className="px-6 py-3  bg-black rounded-[5px]  relative group transition duration-200 text-white hover:bg-transparent">
            {loading ? "Loading..." : "Join"}{" "}
          </div>
        </button>
      </div>
      {/* <div className="flex flex-row items-center justify-center mb-10 w-full">
        <AnimatedTooltip items={people} />
      </div> */}
      <div className="flex flex-row gap-2 md:gap-6 w-full justify-center py-8 absolute bottom-0 backdrop-blur-lg items-center">
        <p className="text-sm md:text-md text-white">@2024 Citrana</p>
        <p className="text-sm md:text-md text-white">About Us</p>
        <p className="text-sm md:text-md text-white">Contact Us</p>
        <p className="text-sm md:text-md text-white">Terms and Conditions</p>
        <p className="text-sm md:text-md text-white">Privacy Policy</p>
      </div>
    </div>
  );
};

export default Footer;
