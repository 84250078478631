import React from "react";
import { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="flex flex-row justify-between px-6 md:px-12 top-6 py-2 md:py-6 fixed w-full z-50 bg-gray-200 bg-opacity-5 md:bg-transparent">
        <img
          src={"../klogo.png"}
          className="left-1/2 top-12 py-2 md:py-0 w-32 md:w-44 z-10"
          alt="logo"
          onClick={() =>
            document
              .getElementById("hero")
              .scrollIntoView({ behavior: "smooth" })
          }
        />
        <div className="hidden md:flex flex-row justify-center gap-4">
          {/* <button
            className="p-[2px] relative"
            onClick={() =>
              document
                .getElementById("use-cases")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="absolute inset-0 bg-gradient-to-r from-bl to-glassbg rounded-lg" />
            <div className="px-6 py-3  bg-black rounded-[5px]  relative group transition duration-200 text-white hover:bg-transparent">
              What We Offer
            </div>
          </button> */}
          <button
            className="p-[2px] relative"
            onClick={() =>
              document
                .getElementById("demo")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="absolute inset-0 bg-gradient-to-r from-bl to-glassbg rounded-lg" />
            <div className="px-6 py-3  bg-black rounded-[5px]  relative group transition duration-200 text-white hover:bg-transparent">
              Product
            </div>
          </button>
          <button
            className="p-[2px] relative"
            onClick={() =>
              document
                .getElementById("pricing")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="absolute inset-0 bg-gradient-to-r from-bl to-glassbg rounded-lg" />
            <div className="px-6 py-3  bg-black rounded-[5px]  relative group transition duration-200 text-white hover:bg-transparent">
              Pricing
            </div>
          </button>
          {/* <button
            className="p-[2px] relative"
            onClick={() =>
              document
                .getElementById("ourteam")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <div className="absolute inset-0 bg-gradient-to-r from-bl to-glassbg rounded-lg" />
            <div className="px-6 py-3  bg-black rounded-[5px]  relative group transition duration-200 text-white hover:bg-transparent">
              Team
            </div>
          </button> */}
        </div>
        <div className="flex items-center md:hidden">
          <FaBars
            className="text-white cursor-pointer"
            size={24}
            onClick={toggleMenu}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-sm"
          onClick={toggleMenu}
        >
          <div className="bg-bl text-xl p-12 bg-opacity-30 backdrop-blur-md flex flex-col justify-center gap-12">
            <button
              className="block mb-4 text-center text-white"
              onClick={() =>
                toggleMenu() ||
                document
                  .getElementById("use-cases")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Use Cases
            </button>
            <button
              className="block mb-4 text-center text-white"
              onClick={() =>
                toggleMenu() ||
                document
                  .getElementById("demo")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Product
            </button>
            <button
              className="block mb-4 text-center text-white"
              onClick={() =>
                toggleMenu() ||
                document
                  .getElementById("pricing")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Pricing
            </button>
            <button
              className="block mb-4 text-center text-white"
              onClick={() =>
                toggleMenu() ||
                document
                  .getElementById("ourteam")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Team
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
